import React, { useState, useContext } from "react";
import { FirstContext } from "../../Context/FirstContext";


import {
  basketballIcon,
  boxingIcon,
  footballIcon,
  formulaIcon,
  mmaIcon,
  soccerIcon,
  twitterIcon,
} from "../../Base/SVG";

export default function Side({
  setLeaguePass,
  leaguePass,
  close,
  menu,
  setModal,
  rewards,
}) {
  const [tooltip, setTooltip] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const [tooltip3, setTooltip3] = useState(false);

  const { setOpenedModal } =
    useContext(FirstContext);

  return (
    <div className={"side " + (menu ? "active" : "")} onClick={close}>
      <div className="side__inner">
        <button
          type="button"
          className="header__inner-started"
          onClick={() => setModal("started")}
        >
          Getting started ?
        </button>
        <button type="button" className="header__inner-connect">
          <div className="header__inner-connect-inner">Connect my wallet</div>
        </button>
        <div className="side__selected">
          <button
            type="button"
            className={"side__btn active " + (leaguePass ? "league" : "")}
          >
            <div className="side__btn-inner">{formulaIcon} Formula 1</div>
          </button>
          <div className="side__selected-games">
            <div className="side__selected-game active">
              <div className="side__selected-game-image">
                <img
                  src={process.env.PUBLIC_URL + "/images/helmet.png"}
                  alt=""
                />
              </div>
              <div className="side__selected-game-inner">
                <div className="side__selected-game-title">
                  <h4>Clash of Champions</h4>
                </div>
                <div className="side__selected-game-subtitle">Game 1</div>
              </div>
            </div>
            <div
              className="side__selected-game"
              onClick={() => {
                setTooltip(true);
                setTimeout(() => {
                  setTooltip(false);
                }, 2000);
              }}
            >
              {/* <div className="side__selected-game-tooltip">
                To play this game mode you need to be registered to the F1
                League
              </div> */}
              <div className={"tooltip " + (tooltip ? "active" : "")}>
                <p>Work in progress</p>
              </div>
              <div className="side__selected-game-inner">
                <div className="side__selected-game-title">
                  <h4>Rival Racers</h4>
                </div>
                <div className="side__selected-game-subtitle">Game 2</div>
              </div>
            </div>
          </div>
          {leaguePass ? (
            <button
              type="button"
              className={"side__rewards active " + (rewards ? "" : "hov")}
              onClick={() => {
                if (rewards) {
                  setModal("tooltip");
                } else {
                  setTooltip3(true);
                  setTimeout(() => {
                    setTooltip3(false);
                  }, 2000);
                }
              }}
            >
              Rewards
              <div className={"tooltip " + (tooltip3 ? "active" : "")}>
                <p>
                  No rewards <br /> to claim yet
                </p>
              </div>
            </button>
          ) : (
            <div className="side__league">
              <div className="side__league-title">
                <p className="xsm">Want to compete and earn cryptos ?</p>
              </div>
              <button
                type="button"
                className="side__league-box"
                onClick={() => setModal("connect")}
              // target="_blank"
              // href="https://docs.google.com/forms/d/e/1FAIpQLSfKjT1C-SGUILFzeS2pEYCXKehR2R8Z9AJUnue74ze4jJ15LA/viewform"
              >
                <div className="side__league-box-inner">
                  <p className="xsm">Get your pass</p>
                  <h5>F1 League</h5>
                </div>
              </button>
            </div>
          )}

          {/* <button
            type="button"
            className="side__btn election-button"
            onClick={() => {
              setTooltip2(true);
              setTimeout(() => {
                setTooltip2(false);
              }, 2000);
            }}
          >
            <div className={"tooltip " + (tooltip2 ? "active" : "")}>
                <p>Work in progress</p>
              </div> 
            <div onClick={() => window.location.href = "https://uselection.spredict.io"} className="side__btn-inner"><img className="election-icon" src={process.env.PUBLIC_URL + "/images/flags/6.png"} alt="" /> US ELECTION</div>
          </button> */}
        </div>
        <div className="side__other">
          <div className="side__btns">
            {/* <button type="button" className="side__btn">
              <div className="side__btn-inner">{mmaIcon} MMA</div>
            </button>
            <button type="button" className="side__btn">
              <div className="side__btn-inner">{boxingIcon} Boxing</div>
            </button> */}

            {/* <button type="button" className="side__btn">
              <div className="side__btn-inner">{footballIcon} Football</div>
            </button>
            <button type="button" className="side__btn">
              <div className="side__btn-inner">{basketballIcon} Basketball</div>
            </button> */}
          </div>
        </div>
        <div className="info__socials-outer">
          <div className="info__socials">
            <a
              href="https://twitter.com/Spredict_io"
              target="_blank"
              className="info__social"
            >
              {twitterIcon}
            </a>
            <a
              href="https://spredict.io"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/language.png"}
                alt=""
              />
            </a>

            <a
              href="https://t.me/SpredictOfficialPortal"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/telegram.png"}
                alt=""
              />
            </a>
          </div>
          <div className="info__socials">
            <a
              href="https://discord.gg/spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/discord.png"}
                alt=""
              />
            </a>
            <a
              href="https://medium.com/@Spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/medium.png"}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
