import React, { useEffect, useState, useContext } from "react";
import { AnimatePresence } from "framer-motion";
import Header from "../Base/Header";
import Side from "./components/Side";
import Content from "./components/Content";
import Info from "./components/Info";
import Modal from "../Base/Modal";
import ClashMod from "./components/ClashMod";
import { RaceModul } from "../Base/RaceModul";
import Tooltip from "./components/Tooltip";
import { FirstContext } from "../Context/FirstContext";
import Started from "./components/Started";
import ModalS from "../Base/ModalS";
import ConnectModal from "./components/ConnectModal";
import PayModal from "./components/PayModal";
import CongratsModal from "./components/CongratsModal";


export default function Index() {
  const { form, updateForm,  modal, setModal, setOpenedModal, race} = useContext(FirstContext);
  // const [form, setForm] = useState({
  //   R001: [],
  //   R002: [],
  //   R003: [],
  //   R004: [],
  //   R005: [],
  //   R006: [],
  //   R007: [],
  //   R008: [],
  //   R009: [],
  //   R010: [],
  //   R011: [],
  //   R012: [],
  //   R013: [],
  //   R014: [],
  //   R015: [],
  //   R016: [],
  //   R017: [],
  //   R018: [],
  //   R019: [],
  //   R020: [],
  //   R021: [],
  //   R022: [],
  //   R023: [],
  //   R024: [],
  // });

  // const updateForm = (data) => {
  //   setForm((form) => ({ ...form, ...data }));
  // };

  const [menu, setMenu] = useState(false);
  const [leaguePass, setLeaguePass] = useState(false);
  const [rewards, setRewards] = useState(true);

  const close = (e) => {
    if (e.target === e.currentTarget) {
      setMenu(false);
      setOpenedModal(false);
    }
  };
  useEffect(() => {
    if (menu && modal) {
      document.body.classList.add("active");
    } else if (menu) {
      document.body.classList.add("active");
    } else if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu, modal]);

  // console.log("form", form);
  return (
    <>
      <Header menu={menu} setMenu={setMenu} setModal={setModal} />
      <main>
        <div className="wrapper">
          <Side
            menu={menu}
            close={close}
            setLeaguePass={setLeaguePass}
            leaguePass={leaguePass}
            setModal={setModal}
            setRewards={setRewards}
            rewards={rewards}
          />
          <Content
            race={race}
            leaguePass={leaguePass}
            setModal={setModal}
            form={form}
            updateForm={updateForm}
          />
          <Info race={race} />
        </div>
      </main>
      <AnimatePresence>
        {modal === "inst" && (
          <Modal setModal={setModal}>
            <ClashMod setModal={setModal} />
          </Modal>
        )}
        {modal === "tooltip" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <Tooltip setModal={setModal} setRewards={setRewards} />
          </Modal>
        )}
        {modal === "started" && (
          <ModalS setModal={setModal}>
            <Started setModal={setModal} />
          </ModalS>
        )}
        {modal === "connect" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <ConnectModal setModal={setModal} />
          </Modal>
        )}
        {modal === "pay" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <PayModal setModal={setModal} />
          </Modal>
        )}
        {modal === "congrats" && (
          <Modal addClass={"gold"} setModal={setModal}>
            <CongratsModal setLeaguePass={setLeaguePass} setModal={setModal} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
