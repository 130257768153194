import React, { useState, useContext, useEffect } from "react";
import { closeIcon, statusIcon } from "../../Base/SVG";
import CustomSelect from "../../Base/CustomSelect";
import { FirstContext } from "../../Context/FirstContext";
import { chainData }  from "../../Base/Tokens"
import feedAbi from "../../Abi/feed.json"
import tokenAbi from "../../Abi/token.json"

const dollarsPrice = 45n
const decimals = 8n

export default function PayModal({ setModal }) {
  const [nonce, setNonce] = useState(0);
  const [error, setError] = useState(false);
  const [balance, setBalance] = useState(0);
  const [secondIndex, setSecondIndex] = useState(0);
  const [price, setPrice] = useState(0n);
  const [tokenData, setTokenData] = useState();
  const {
    signer,
    account,
    shortedAddress,
    allowedModalNetwork,
    switchNetwork,
    chainID,
    modalNetwork,
    setOpenBackdrop,
    setOpenSuccessMessage,
    setOpenErrorMessage,
    ethers
  } = useContext(FirstContext);

  // useEffect(() => {
  //   if (balance < 44.43) {
  //     setError(true);
  //   } else {
  //     setError(false);
  //   }
  // }, [balance]);

  useEffect(()=> {
    if(modalNetwork.includes(chainID)) {
      setSecondIndex(modalNetwork.indexOf(chainID))
      onChangeToken(chainData[modalNetwork.indexOf(chainID)].tokens[0])
    }
    
  }, [chainID])

  const getBalance = async addr =>{
    if(window.ethereum && signer && allowedModalNetwork) {
      try {
        const addrBalance = await signer.provider.getBalance(addr);
        return addrBalance;
      } catch(e) {
        console.log(e.message);
      }
    }
  }




  const onChangeNetwork = data => {
    switchNetwork(data.chainid);
  }
  const onChangeToken = async token => {
    setTokenData(token);
    const feedContract = new ethers.Contract(token.pricefeed, feedAbi, signer)
    const answer = await feedContract.latestAnswer()
    setPrice((10n ** (decimals * 2n)) / answer * dollarsPrice)
    if(token.address !== ethers.ZeroAddress) {
      const tokenContract = new ethers.Contract(token.address, tokenAbi, signer)
      const b = await tokenContract.balanceOf(account);
      setBalance(b)
    } else {
      const b = await getBalance(account)     
      setBalance(b)
    }
  }

  const payPass = async() => {
    try {

      let tx;
      const multisigAddress = chainData[modalNetwork.indexOf(chainID)].multisig;
      if(price && tokenData) {
          if(tokenData.address !== ethers.ZeroAddress) {
            const ERC20Contract =  new ethers.Contract(tokenData.address, tokenAbi, signer)
            const ERC20Decimals = await ERC20Contract.decimals();
            
            const priceInERC20 = ERC20Decimals > decimals 
            ? price * 10n ** (ERC20Decimals - decimals) 
            : price / 10n ** (decimals - ERC20Decimals);
            console.log(price)
            console.log(priceInERC20)

            
            if(balance >= priceInERC20) {
              tx = await ERC20Contract.transfer(multisigAddress, priceInERC20);
            }
          } else {
            const priceInNative = price * 10n ** (18n - decimals);

            if(balance > priceInNative) {
              tx = await signer.sendTransaction({
                to: multisigAddress,
                value: priceInNative,
              })
            }
          }

          if(tx) {
            setOpenBackdrop(true);
            await tx.wait()
            setModal("congrats")
            setOpenBackdrop(false)
            setOpenSuccessMessage(true)
          } else {
            setOpenErrorMessage(true)
          }
      }
    } catch(e) {
      setOpenBackdrop(false)
      setOpenErrorMessage(true)
      console.log(e.message)
    }
  }


  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalPay">
        <div className="modalPay__info">
          <div className="modalPay__info-inner">
            <CustomSelect
              chainID={chainID}
              modalNetwork={modalNetwork} 
              switchNetwork={switchNetwork} 
              list={chainData} 
              selected={chainData[secondIndex]}
              onChangeNetwork = {onChangeNetwork}
            />
            <div className="modalPay__info-wallet">
              <div className="modalPay__info-wallet-inner">
                <div className="modalPay__info-wallet-text">
                {shortedAddress(account)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalPay__body">
          <div className="modalPay__body-inner">
            <div className="modalPay__body-info">
              <div className="modalPay__body-info-inner">
                <div className="modalPay__body-info-content">
                  <p>Your balance</p>
                  <h5>{parseFloat(ethers.formatEther(balance || 0n)).toFixed(6)}</h5>
                </div>
                <CustomSelect
                  type={"second"}
                  list={chainData[secondIndex].tokens}
                  selected={chainData[secondIndex].tokens[0]}
                  onChangeToken={onChangeToken}
                />
              </div>
            </div>
            <div className="modalPay__card-outer">
              <div className="modalPay__card">
                <div className="modalPay__card-inner">
                  <h5>F1 League</h5>
                </div>
              </div>
            </div>
            <div className={"modalPay__body-price " + (error ? "error" : "")}>
              <div className="modalPay__body-price-inner">
                <p>Price: </p>
                <h5>{ethers.formatUnits(price, decimals)}</h5>
              </div>
            </div>
            {error && (
              <div className="modalPay__body-error">
                <p>Fund required invalid</p>
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          className="modalPay__btn"
          onClick={() => payPass()}
          disabled={error}
        >
          Get your league pass
        </button>
      </div>
    </>
  );
}
